<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Días de vacaciones" :exportInfo="currItems">
      <template v-slot:buttons>
        <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="dialogs.new = true" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search" @current-items="getFiltered"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.iddia_vacacion)">mdi-delete</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <Dialog-Form :estate="dialogs.new" title="Nuevo día de vacaciones">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="save()" :loading="loading" />
      </template>
      <v-form ref="formNew" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.new.antiguedad" label="Año de antigûedad" prepend-icon="mdi-account-group" :rules="rules.antiguedad" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="forms.new.dias_vacaciones" label="Días de vacaciones" prepend-icon="mdi-account-group" :rules="rules.dias_vacaciones" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="forms.new.dias_adelantos" label="Días adelantar" prepend-icon="mdi-account-group" :rules="rules.dias_adelantos" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.update" title="Actualizar día de vacaciones">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="update()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.update.antiguedad" label="Año de antigûedad" prepend-icon="mdi-account-group" :rules="rules.antiguedad" :disabled="loading" disabled dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="forms.update.dias_vacaciones" label="Días de vacaciones" prepend-icon="mdi-account-group" :rules="rules.dias_vacaciones" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="forms.update.dias_adelantos" label="Días adelantar" prepend-icon="mdi-account-group" :rules="rules.dias_adelantos" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar el registro?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="remove()" :loading="loading" />
      </template>
    </Delete-Message>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage } from "@/components";

export default {
  name: "vacationDay",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    headers: [
      { text: "Año de antiguedad", align: "left", sortable: true, value: "antiguedad" },
      { text: "Días de vacaciones", align: "left", sortable: true, value: "dias_vacaciones" },
      { text: "Días adelantar", align: "left", sortable: true, value: "dias_adelantos" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    forms: {
      new: {
        antiguedad: '',
        dias_vacaciones: '',
        dias_adelantos: ''  
      },
      update: {
        iddia_vacacion: '',
        antiguedad: '',
        dias_vacaciones: '',
        dias_adelantos: ''  
      },
    },
    items: [],
    selected: [],
    deleted: null,
    currItems: [],
    rules: {
      antiguedad: [(v) => /^\d{1,3}$/.test(v) || "El campo es inválido"],
      dias_vacaciones: [(v) => /^\d{1,3}$/.test(v) || "El campo es inválido"],
      dias_adelantos: [(v) => /^\-?\d{1,3}$/.test(v) || "El campo es inválido"],
    },
    dialogs: {
      new: false,
      update: false,
      remove: false
    },
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.catalog + '/vacationDay')
        .then((response) => {
            this.items = response.data.data
        })
        .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          this.forms.new[key] = "";
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update) {
        this.$refs.formUpdate.reset();
        for (let key in this.forms.update) {
          this.forms.update[key] = "";
        }
        this.dialogs.update = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
    },
    save() {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].antiguedad == this.forms.new.antiguedad) {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = "Existe un registro con los mismos años de antigûedad";
          return false
        }
      }
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        this.axios
          .post(services.routes.catalog + '/vacationDay', { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Registro agregado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    view(data) {
      this.forms.update = JSON.parse(JSON.stringify(data));
      this.dialogs.update = true;
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .post(services.routes.catalog + '/vacationDay', { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Registro actualizado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        this.axios
          .delete(services.routes.catalog + '/vacationDay', { data: { data: { iddia_vacacion: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Registro eliminado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
    getFiltered(values) {
      this.currItems = values
    }
  },
};
</script>

<style scoped></style>
